import * as SingaporeConfig from "./country/singapore";
export const POI_TYPE = ["TRAIN", "SCHOOL", "BUS"];
export const STREET_POI_TYPE = "STREET";
export const DEFAULT_SEARCH_RECORD_LIMIT = 30;

export const SPORTS_CATEGORY = [
  {
    code: "soccer",
    label: "Soccer",
  },
  {
    code: "basketball",
    label: "Basketball",
  },
  {
    code: "tennis",
    label: "Tennis",
  },
  {
    code: "baseball",
    label: "Baseball",
  },
  {
    code: "golf",
    label: "Golf",
  },
  {
    code: "running",
    label: "Running",
  },
  {
    code: "volleyball",
    label: "Volleyball",
  },
  {
    code: "badminton",
    label: "Badminton",
  },
  {
    code: "swimming",
    label: "Swimming",
  },
  {
    code: "boxing",
    label: "Boxing",
  },
  {
    code: "table-tennis",
    label: "Table tennis",
  },
  {
    code: "skiing",
    label: "Skiing",
  },
  {
    code: "ice-skating",
    label: "Ice skating",
  },
  {
    code: "roller-skating",
    label: "Roller skating",
  },
  {
    code: "cricket",
    label: "Cricket",
  },
  {
    code: "rugby",
    label: "Rugby",
  },
  {
    code: "pool",
    label: "Pool",
  },
  {
    code: "darts",
    label: "Darts",
  },
  {
    code: "football",
    label: "Football",
  },
  {
    code: "bowling",
    label: "Bowling",
  },
  {
    code: "ice-hockey",
    label: "Ice hockey",
  },
  {
    code: "surfing",
    label: "Surfing",
  },
  {
    code: "karate",
    label: "Karate",
  },
  {
    code: "horse-racing",
    label: "Horse racing",
  },
  {
    code: "snowboarding",
    label: "Snowboarding",
  },
  {
    code: "skateboarding",
    label: "Skateboarding",
  },
  {
    code: "cycling",
    label: "Cycling",
  },
  {
    code: "archery",
    label: "Archery",
  },
  {
    code: "fishing",
    label: "Fishing",
  },
  {
    code: "gymnastics",
    label: "Gymnastics",
  },
  {
    code: "figure-skating",
    label: "Figure skating",
  },
  {
    code: "rock-climbing",
    label: "Rock climbing",
  },
  {
    code: "sumo-wrestling",
    label: "Sumo wrestling",
  },
  {
    code: "taekwondo",
    label: "Taekwondo",
  },
  {
    code: "fencing",
    label: "Fencing",
  },
  {
    code: "water-skiing",
    label: "Water skiing",
  },
  {
    code: "jet-skiing",
    label: "Jet skiing",
  },
  {
    code: "weight-lifting",
    label: "Weight lifting",
  },
  {
    code: "scuba-diving",
    label: "Scuba diving",
  },
  {
    code: "judo",
    label: "Judo",
  },
  {
    code: "wind-surfing",
    label: "Wind surfing",
  },
  {
    code: "kickboxing",
    label: "Kickboxing",
  },
  {
    code: "sky-diving",
    label: "Sky diving",
  },
  {
    code: "hang-gliding",
    label: "Hang gliding",
  },
  {
    code: "bungee-Jumping",
    label: "Bungee Jumping",
  },
];

export const PREFERRED_GENDER = [
  {
    code: "any",
    label: "Any",
  },
  {
    code: "M",
    label: "Male",
  },
  {
    code: "F",
    label: "Female",
  },
];

export const SEARCH_SORTING_OPTIONS = [
  { code: "newListings", label: "Sort: New Listings" },
];

export const PREFERRED_AGE_GROUP = [
  { code: "0-4", label: "0-4 years" },
  { code: "5-9", label: "5-9 years" },
  { code: "10-19", label: "10-19 years" },
  { code: "20-26", label: "20-26 years" },
  { code: "27-39", label: "27-39 years" },
  { code: "40-55", label: "40-55 years" },
  { code: "56-64", label: "56-64 years" },
  { code: "65+", label: "65+ years" },
];

export const COUNTRIES = [
  {
    code: "yerevan",
    label: "Yerevan",
    country: "Armenia",
  },
  {
    code: "sydney",
    label: "Sydney",
    country: "Australia",
  },
  {
    code: "brisbane",
    label: "Brisbane",
    country: "Australia",
  },
  {
    code: "melbourne",
    label: "Melbourne",
    country: "Australia",
  },
  {
    code: "vienna",
    label: "Vienna",
    country: "Austria",
  },
  {
    code: "minsk",
    label: "Minsk",
    country: "Belarus",
  },
  {
    code: "toronto",
    label: "Toronto",
    country: "Canada",
  },
  {
    code: "calgary",
    label: "Calgary",
    country: "Canada",
  },
  {
    code: "zagreb",
    label: "Zagreb",
    country: "Croatia",
  },
  {
    code: "prague",
    label: "Prague",
    country: "Czech Republic",
  },
  {
    code: "copenhagen",
    label: "Copenhagen",
    country: "Denmark",
  },
  {
    code: "helsinki",
    label: "Helsinki",
    country: "Finland",
  },
  {
    code: "paris",
    label: "Paris",
    country: "France",
  },
  {
    code: "nanterre",
    label: "Nanterre",
    country: "France",
  },
  {
    code: "lescar",
    label: "Lescar",
    country: "France",
  },
  {
    code: "biscarrosse",
    label: "Biscarrosse",
    country: "France",
  },
  {
    code: "marseille",
    label: "Marseille",
    country: "France",
  },
  {
    code: "la-rochelle",
    label: "La Rochelle",
    country: "France",
  },
  {
    code: "berlin",
    label: "Berlin",
    country: "Germany",
  },
  {
    code: "munich",
    label: "Munich",
    country: "Germany",
  },
  {
    code: "hamburg",
    label: "Hamburg",
    country: "Germany",
  },
  {
    code: "frankfurt",
    label: "Frankfurt",
    country: "Germany",
  },
  {
    code: "athens",
    label: "Athens",
    country: "Greece",
  },
  {
    code: "pireas",
    label: "Pireas",
    country: "Greece",
  },
  {
    code: "hong-kong",
    label: "Hong Kong",
    country: "Hong Kong",
  },
  {
    code: "budapest",
    label: "Budapest",
    country: "Hungary",
  },
  {
    code: "delhi",
    label: "New Delhi",
    country: "India",
  },
  {
    code: "mumbai",
    label: "Mumbai",
    country: "India",
  },
  {
    code: "pune",
    label: "Pune",
    country: "India",
  },
  {
    code: "chandigarh",
    label: "Chandigarh",
    country: "India",
  },
  {
    code: "chennai",
    label: "Chennai",
    country: "India",
  },
  {
    code: "bengaluru",
    label: "Bengaluru",
    country: "India",
  },
  {
    code: "hyderabad",
    label: "Hyderabad",
    country: "India",
  },
  // {
  //   code: "visakhapatnam",
  //   label: "Visakhapatnam",
  //   country: "India",
  // },
  // {
  //   code: "kolkata",
  //   label: "Kolkata",
  //   country: "India",
  // },
  {
    code: "gurugram",
    label: "Gurugram",
    country: "India",
  },
  {
    code: "noida",
    label: "Noida",
    country: "India",
  },
  // {
  //   code: "haifa",
  //   label: "Haifa",
  //   country: "Israel",
  // },
  {
    code: "rome",
    label: "Rome",
    country: "Italy",
  },
  {
    code: "milan",
    label: "Milan",
    country: "Italy",
  },
  {
    code: "dublin",
    label: "Dublin",
    country: "Ireland",
  },
  {
    code: "kuala-lumpur",
    label: "Kuala Lumpur",
    country: "Malaysia",
  },
  {
    code: "mexico-city",
    label: "Mexico City",
    country: "Mexico",
  },
  {
    code: "tula",
    label: "Tula",
    country: "Mexico",
  },
  {
    code: "casablanca",
    label: "Casablanca",
    country: "Morocco",
  },
  {
    code: "fez",
    label: "Fez",
    country: "Morocco",
  },
  {
    code: "tangier",
    label: "Tangier",
    country: "Morocco",
  },
  {
    code: "quezon-city",
    label: "Quezon City",
    country: "Philippines",
  },
  {
    code: "manila",
    label: "Manila",
    country: "Philippines",
  },
  {
    code: "davao-city",
    label: "Davao City",
    country: "Philippines",
  },
  {
    code: "warsaw",
    label: "Warsaw",
    country: "Poland",
  },
  {
    code: "gdansk",
    label: "Gdansk",
    country: "Poland",
  },
  {
    code: "lisbon",
    label: "Lisbon",
    country: "Portugal",
  },
  {
    code: "bucharest",
    label: "Bucharest",
    country: "Romania",
  },
  {
    code: "saint-petersburg",
    label: "Saint Petersburg",
    country: "Russia",
  },
  {
    code: "moscow",
    label: "Moscow",
    country: "Russia",
  },
  {
    code: "auckland",
    label: "Auckland",
    country: "New Zealand",
  },
  {
    code: "wellington",
    label: "Wellington",
    country: "New Zealand",
  },
  {
    code: "christchurch",
    label: "Christchurch",
    country: "New Zealand",
  },
  {
    code: "hamilton",
    label: "Hamilton",
    country: "New Zealand",
  },
  {
    code: "napier-hastings",
    label: "Napier Hastings",
    country: "New Zealand",
  },
  {
    code: "singapore",
    label: "Singapore",
    country: "Singapore",
  },
  {
    code: "madrid",
    label: "Madrid",
    country: "Spain",
  },
  {
    code: "barcelona",
    label: "Barcelona",
    country: "Spain",
  },
  {
    code: "cordoba",
    label: "Cordoba",
    country: "Spain",
  },
  {
    code: "stockholm",
    label: "Stockholm",
    country: "Sweden",
  },
  {
    code: "bangkok",
    label: "Bangkok",
    country: "Thailand",
  },
  {
    code: "istanbul",
    label: "Istanbul",
    country: "Turkey",
  },
  {
    code: "dubai",
    label: "Dubai",
    country: "UAE",
  },
  {
    code: "london",
    label: "London",
    country: "United Kingdom",
  },
  {
    code: "new-york",
    label: "New York",
    country: "United States America",
  },
  {
    code: "chicago",
    label: "Chicago",
    country: "United States America",
  },
  {
    code: "dallas",
    label: "Dallas",
    country: "United States America",
  },
  {
    code: "columbus",
    label: "Columbus",
    country: "United States America",
  },
  {
    code: "los-angeles",
    label: "Los Angeles",
    country: "United States America",
  },
  {
    code: "brooklyn",
    label: "Brooklyn",
    country: "United States America",
  },
  {
    code: "houston",
    label: "Houston",
    country: "United States America",
  },
  {
    code: "queens",
    label: "Queens",
    country: "United States America",
  },
  {
    code: "phoenix",
    label: "Phoenix",
    country: "United States America",
  },
  {
    code: "philadelphia",
    label: "Philadelphia",
    country: "United States America",
  },
  {
    code: "manhattan",
    label: "Manhattan",
    country: "United States America",
  },
  {
    code: "san-antonio",
    label: "San Antonio",
    country: "United States America",
  },
  {
    code: "san-francisco",
    label: "San Francisco",
    country: "United States America",
  },
  {
    code: "detroit",
    label: "Detroit",
    country: "United States America",
  },
  {
    code: "boston",
    label: "Boston",
    country: "United States America",
  },
  {
    code: "austin",
    label: "Austin",
    country: "United States America",
  },
  {
    code: "san-jose",
    label: "San Jose",
    country: "United States America",
  },
  {
    code: "indianapolis",
    label: "Indianapolis",
    country: "United States America",
  },
  {
    code: "charlotte",
    label: "Charlotte",
    country: "United States America",
  },
  {
    code: "seoul",
    label: "Seoul",
    country: "South Korea",
  },
  {
    code: "busan",
    label: "Busan",
    country: "South Korea",
  },
  {
    code: "incheon",
    label: "Incheon",
    country: "South Korea",
  },
];

// Yerevan - Armenia
// Biscarrosse - France
// Yerevan - Ftance
// Marseille - France
// Zagreb - Crotia
// Haifa - Israel
// Hausen bei Brugg - switzerland

export const COUNTRIES_WITH_PHONE_CODE = [
  { code: "+65", label: "Singapore (+65)" },
  { code: "+1", label: "USA (+1)" },
  { code: "+61", label: "Australia (+61)" },
  { code: "+64", label: "New Zealand (+64)" },
  { code: "+44", label: "United Kingdom (+44)" },
  { code: "+91", label: "India (+91)" },
  { code: "+82", label: "South Korea (+82)" },
  { code: "+86", label: "China (+86)" },
  { code: "+81", label: "Japan (+81)" },
  { code: "+27", label: "South Africa (+27)" },
  { code: "+7", label: "Russia (+7)" },
  { code: "+1", label: "Canada (+1)" },
  { code: "+33", label: "France (+33)" },
  { code: "+60", label: "Malaysia (+60)" },
  { code: "+66", label: "Thailand (+66)" },
  { code: "+971", label: "UAE (+971)" },
  { code: "+49", label: "Germany (+49)" },
  { code: "+852", label: "Hong Kong (+852)" },
  { code: "+90", label: "Turkey (+90)" },
  { code: "+34", label: "Spain (+34)" },
  { code: "+39", label: "Italy (+39)" },
  { code: "+40", label: "Romania (+40)" },
  { code: "+375", label: "Belarus (+375)" },
  { code: "+43", label: "Austria (+43)" },
  { code: "+48", label: "Poland (+48)" },
  { code: "+36", label: "Hungary (+36)" },
  { code: "+45", label: "Denmark (+45)" },
  { code: "+46", label: "Sweden (+46)" },
  { code: "+358", label: "Finland (+358)" },
  { code: "+212", label: "Morocco (+212)" },
  { code: "+63", label: "Philippines (+63)" },
  { code: "+374", label: "Armenia (+374)" },
  { code: "+385", label: "Crotia (+385)" },
  { code: "+972", label: "Israel (+972)" },
  { code: "+41", label: "Switzerland (+41)" },
  { code: "+32", label: "Belgium (+32)" },
  { code: "+351", label: "Portugal (+351)" },
  { code: "+52", label: "Mexico (+52)" },
  { code: "+30", label: "Greece (+30)" },
  { code: "+353", label: "Ireland (+353)" },
];

export const AGE_LIST = [
  { code: 15, label: "15 years" },
  { code: 16, label: "16 years" },
  { code: 17, label: "17 years" },
  { code: 18, label: "18 years" },
  { code: 19, label: "19 years" },
  { code: 20, label: "20 years" },
  { code: 21, label: "21 years" },
  { code: 22, label: "22 years" },
  { code: 23, label: "23 years" },
  { code: 24, label: "24 years" },
  { code: 25, label: "25 years" },
  { code: 26, label: "26 years" },
  { code: 27, label: "27 years" },
  { code: 28, label: "28 years" },
  { code: 29, label: "29 years" },
  { code: 30, label: "30 years" },
  { code: 31, label: "31 years" },
  { code: 32, label: "32 years" },
  { code: 33, label: "33 years" },
  { code: 34, label: "34 years" },
  { code: 35, label: "35 years" },
  { code: 36, label: "36 years" },
  { code: 37, label: "37 years" },
  { code: 38, label: "38 years" },
  { code: 39, label: "39 years" },
  { code: 40, label: "40 years" },
  { code: 41, label: "41 years" },
  { code: 42, label: "42 years" },
  { code: 43, label: "43 years" },
  { code: 44, label: "44 years" },
  { code: 45, label: "45 years" },
  { code: 46, label: "46 years" },
  { code: 47, label: "47 years" },
  { code: 48, label: "48 years" },
  { code: 49, label: "49 years" },
  { code: 50, label: "50 years" },
  { code: 51, label: "51 years" },
  { code: 52, label: "52 years" },
  { code: 53, label: "53 years" },
  { code: 54, label: "54 years" },
  { code: 55, label: "55 years" },
  { code: 56, label: "56 years" },
  { code: 57, label: "57 years" },
  { code: 58, label: "58 years" },
  { code: 59, label: "59 years" },
  { code: 60, label: "60 years" },
  { code: 61, label: "61 years" },
  { code: 62, label: "62 years" },
  { code: 63, label: "63 years" },
  { code: 64, label: "64 years" },
  { code: 65, label: "65 years" },
  { code: 66, label: "66 years" },
  { code: 67, label: "67 years" },
  { code: 68, label: "68 years" },
  { code: 69, label: "69 years" },
  { code: 70, label: "70 years" },
  { code: 71, label: "71 years" },
  { code: 72, label: "72 years" },
  { code: 73, label: "73 years" },
  { code: 74, label: "74 years" },
  { code: 75, label: "75 years" },
  { code: 76, label: "76 years" },
  { code: 77, label: "77 years" },
  { code: 78, label: "78 years" },
  { code: 79, label: "79 years" },
  { code: 80, label: "80 years" },
  { code: 81, label: "81 years" },
  { code: 82, label: "82 years" },
  { code: 83, label: "83 years" },
  { code: 84, label: "84 years" },
  { code: 85, label: "85 years" },
  { code: 86, label: "86 years" },
  { code: 87, label: "87 years" },
  { code: 88, label: "88 years" },
  { code: 89, label: "89 years" },
  { code: 90, label: "90 years" },
  { code: 91, label: "91 years" },
  { code: 92, label: "92 years" },
  { code: 93, label: "93 years" },
  { code: 94, label: "94 years" },
  { code: 95, label: "95 years" },
  { code: 96, label: "96 years" },
  { code: 97, label: "97 years" },
  { code: 98, label: "98 years" },
  { code: 99, label: "99 years" },
];

const currentYear = new Date().getFullYear() - 10;
const maxYears = 1960;
const years = [];

for (let i = currentYear; i >= maxYears; i--) {
  years.push({
    code: String(i),
    label: String(i),
  });
}

export const YEARS_LIST = years;

export const GENDER = [
  {
    code: "M",
    label: "Male",
  },
  {
    code: "F",
    label: "Female",
  },
];

export const countrySpecificConfig = {
  singapore: SingaporeConfig,
  sydney: {},
  london: {},
};
