export const DISTRICT = [
  {
    code: "D01",
    label: "D01 Boat Quay / Raffles Place / Marina",
  },
  {
    code: "D02",
    label: "D02 Chinatown / Tanjong Pagar",
  },
  {
    code: "D03",
    label: "D03 Alexandra / Commonwealth",
  },
  {
    code: "D04",
    label: "D04 Harbourfront / Telok Blangah",
  },
  {
    code: "D05",
    label: "D05 Buona Vista / West Coast / Clementi New Town",
  },
  {
    code: "D06",
    label: "D06 City Hall / Clarke Quay",
  },
  {
    code: "D07",
    label: "D07 Beach Road / Bugis / Rochor",
  },
  {
    code: "D08",
    label: "D08 Farrer Park / Serangoon Rd",
  },
  {
    code: "D09",
    label: "D09 Orchard / River Valley",
  },
  {
    code: "D10",
    label: "D10 Tanglin / Holland / Bukit Timah",
  },
  {
    code: "D11",
    label: "D11 Newton / Novena",
  },
  {
    code: "D12",
    label: "D12 Balestier / Toa Payoh",
  },
  {
    code: "D13",
    label: "D13 Macpherson / Potong Pasir",
  },
  {
    code: "D14",
    label: "D14 Eunos / Geylang / Paya Lebar",
  },
  {
    code: "D15",
    label: "D15 East Coast / Marine Parade",
  },
  {
    code: "D16",
    label: "D16 Bedok / Upper East Coast",
  },
  {
    code: "D17",
    label: "D17 Changi Airport / Changi Village",
  },
  {
    code: "D18",
    label: "D18 Pasir Ris / Tampines",
  },
  {
    code: "D19",
    label: "D19 Hougang / Punggol / Sengkang",
  },
  {
    code: "D20",
    label: "D20 Ang Mo Kio / Bishan / Thomson",
  },
  {
    code: "D21",
    label: "D21 Clementi Park / Upper Bukit Timah",
  },
  {
    code: "D22",
    label: "D22 Boon Lay / Jurong / Tuas",
  },
  {
    code: "D23",
    label: "D23 Dairy Farm / Bukit Panjang / Choa Chu Kang",
  },
  {
    code: "D24",
    label: "D24 Lim Chu Kang / Tengah",
  },
  {
    code: "D25",
    label: "D25 Admiralty / Woodlands",
  },
  {
    code: "D26",
    label: "D26 Mandai / Upper Thomson",
  },
  {
    code: "D27",
    label: "D27 Sembawang / Yishun",
  },
  {
    code: "D28",
    label: "D28 Seletar / Yio Chu Kang",
  },
];

export const SEARCH_SORTING_OPTIONS = [
  { code: "newListings", label: "New Listings" },
  { code: "priceLoToHigh", label: "Price (Lo-Hi)" },
  {
    code: "priceHighToLow",
    label: "Price (Hi-Lo)",
  },
  { code: "bedroom", label: "Bedroom" },
  { code: "bathroom", label: "Bathrooms" },
  {
    code: "squareFeetLowToHigh",
    label: "Square Feet (Lo-Hi)",
  },
  {
    code: "squareFeetHighToLow",
    label: "Square Feet (Hi-Lo)",
  },
];

export const TRAIN_LINE_COLORS = {
  CR: {
    color: "#9CD306",
    name: "Cross-Island Line",
  },
  NE: {
    color: "#9E27B5",
    name: "North-East Line",
  },
  PTC: {
    color: "#738376",
    name: "Punggol LRT Line",
  },
  CC: {
    color: "#FF9F18",
    name: "Circle Line",
  },
  DT: {
    color: "#0051B7",
    name: "Downtown Line",
  },
  NS: {
    color: "#E32216",
    name: "North-South Line",
  },
  EW: {
    color: "#009437",
    name: "East-West Line",
  },
  TE: {
    color: "#9E5A1C",
    name: "Thomson-East Line",
  },
  CG: {
    color: "",
    name: "",
  },
  STC: {
    color: "#738376",
    name: "Sengkang LRT Line",
  },
  JR: {
    color: "#00ADBC",
    name: "Jurong-Region Line",
  },
  BP: {
    color: "#738376",
    name: "Bukit-Panjang LRT Line",
  },
  SK: {
    color: "#738376",
    name: "Sengkang LRT Line",
  },
};

export const HDB_ESTATE = [
  {
    code: "AMK",
    label: "ANG MO KIO",
  },
  {
    code: "BB",
    label: "BUKIT BATOK",
  },
  {
    code: "BD",
    label: "BEDOK",
  },
  {
    code: "BH",
    label: "BISHAN",
  },
  {
    code: "BM",
    label: "BUKIT MERAH",
  },
  {
    code: "BP",
    label: "BUKIT PANJANG",
  },
  {
    code: "CCK",
    label: "CHOA CHU KANG",
  },
  {
    code: "CL",
    label: "CLEMENTI",
  },
  {
    code: "CT",
    label: "CENTRAL AREA",
  },
  {
    code: "GL",
    label: "GEYLANG",
  },
  {
    code: "HG",
    label: "HOUGANG",
  },
  {
    code: "JE",
    label: "JURONG EAST",
  },
  {
    code: "JW",
    label: "JURONG WEST",
  },
  {
    code: "KWN",
    label: "KALLANG/WHAMPOA",
  },
  {
    code: "MP",
    label: "MARINE PARADE",
  },
  {
    code: "PG",
    label: "PUNGGOL",
  },
  {
    code: "PRC",
    label: "PASIR RIS",
  },
  {
    code: "QT",
    label: "QUEENSTOWN",
  },
  {
    code: "SB",
    label: "SEMBAWANG",
  },
  {
    code: "SGN",
    label: "SERANGOON",
  },
  {
    code: "SK",
    label: "SENGKANG",
  },
  {
    code: "TAP",
    label: "TAMPINES",
  },
  {
    code: "TG",
    label: "TENGAH",
  },
  {
    code: "TP",
    label: "TOA PAYOH",
  },
  {
    code: "WL",
    label: "WOODLANDS",
  },
  {
    code: "YS",
    label: "YISHUN",
  },
];
